import { AgentSearch, AgentSearchProps } from 'components/agent-search';
import { getThemeOverrideFromRequest } from 'utils/themes';
import HeadData from 'components/head-data';
import { ThemeNames } from 'types/themes';
import Layout from 'components/layout';
import { v4 as uuidv4 } from 'uuid';

import type { GetServerSidePropsContext } from 'next';
import { themes } from 'themes';
import { AgentType } from 'utils/agent-endpoint';

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const sessionId = uuidv4();

  const themeName = getThemeOverrideFromRequest(context.req);
  const theme = themes[themeName];

  let title = 'Experts - eXp Commercial Brokerage';
  let metaDescription = '';

  if (theme) {
    title = `Find a Local Real Estate Agent ${theme.title}`;
    metaDescription = theme.metaDescription;
  }

  if (themeName !== ThemeNames.EXP_COMMERCIAL) {
    return {
      redirect: {
        permanent: true,
        destination: '/404',
      },
    };
  }

  return {
    props: {
      agentType: AgentType.COMMERCIAL,
      initialCountry: 'US',
      sessionId,
      headerData: {
        title,
        metaDescription,
      },
    },
  };
}

export default function AgentsPage(props: AgentSearchProps) {
  return (
    <Layout>
      <HeadData data={props.headerData} />
      <AgentSearch {...props} />
    </Layout>
  );
}